import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Button from '@mui/material/Button';
import LockIcon from '@mui/icons-material/LockOpen';
import Box from '@mui/material/Box';

import logo from './logo.svg';
import './App.css';

import axios from 'axios';
import Reservation from './Reservation';

function App() {
 return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/Reservation" Component={Reservation}  /> 
      </Routes>
    </BrowserRouter>
  );
}

export default App;
