import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import BookOutlined from '@mui/icons-material/BookOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LockIcon from '@mui/icons-material/LockOpen';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoadingButton from '@mui/lab/LoadingButton';

import Image from './apartamento.jpeg'; // Import using relative path

import logo from './logo.svg';
import './App.css';

import axios from 'axios';

const defaultTheme = createTheme();

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://airbnbuilding.com/">
        Airbnbuilding
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Reservation() {
  const params = new URLSearchParams(window.location.search);

  const [openDialog, setOpenDialog] = useState(false);

  const maxOpenGate = 3;

  const [reservation, setReservation] = useState(null);
  const [guests, setGuests] = useState([]);

  const [loadingReservation, setLoadingReservation] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {    
    loadReservation(params.get('code'))
  }, [])

  const handleCheckIn = (event) => {
    event.preventDefault()
    
    const validForms = guests.every(guest => 
      guest.name && !guest.nameError &&
      guest.rg && !guest.rgError &&
      guest.cpf && !guest.cpfError
    );

    if (validForms) {
      setLoading(true)
      
      axios.post('https://api.airbnbuilding.com/guest', {
        reservation: params.get('code'),
        guests: guests.map(guest => ({
          name: guest.name,
          documents: [
            { type: "RG", value: guest.rg },
            { type: "CPF", value: guest.cpf }
          ]
        }))
      })
        .then(res => {
          loadReservation(params.get('code'))
        })
        .catch(err => console.log(err))
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleUnlock = () => {
    setLoading(true)

    axios.post('https://api.airbnbuilding.com/ewelink/unlock', {
      reservation: params.get('code')
    })
      .then(res => {
        setReservation(prevState => ({
          ...prevState,
          unlocked: res.data.unlocked
        }))
      })
      .catch(err => console.log(err))
      .finally(() => {
        setLoading(false)
      })
  }

  const loadReservation = (code) => {
    setLoadingReservation(true)

    axios.get('https://api.airbnbuilding.com/guest', {
      params: {
        reservation: code
      }
    })
      .then(res => {
        console.log(res)

        setReservation(res.data)

        console.log(res.data)

        const initialGuests = Array(res.data.number_of_guests).fill().map(() => ({
          name: '',
          rg: '',
          cpf: '',
          nameError: '',
          rgError: '',
          cpfError: ''
        }));
  
        console.log(initialGuests)

        setGuests(initialGuests);
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoadingReservation(false)
      })
  }

  const handleInputChange = (index, field, value) => {
    const newGuests = [...guests];
    newGuests[index][field] = value;

    if (field === 'name') {
      newGuests[index].nameError = nameValidator(value);
    }
    if (field === 'rg' || field === 'cpf') {
      newGuests[index][field + 'Error'] = nationalDocumentsValidator(value);
    }

    setGuests(newGuests);
  };

  const nameValidator = value => {
    if (value.length < 3) return "Nome deve ter pelo menos 3 letras";
    if (value.split(' ').length < 2) return "Nome completo deve ter pelo menos 2 nomes";
    if (!/^[a-zA-Z ]+$/.test(value))
      return "Nome deve conter apenas letras e espaços";
    return false;
  };

  const nationalDocumentsValidator = value => {
    if (value.length < 5) return "Documento deve ter pelo menos 5 números";
    return false;
  }

  const generateGuestsForm = () => {
    return guests.map((guest, index) => (
      <>
        <Grid container noValidate sx={{ mt: 1 }} direction="column">
          <Typography variant="h6" fontWeight={"bold"}  marginTop={2} inline align='left'>
            Hóspede {index + 1}
          </Typography>
          <Grid item>
            <TextField
              margin="normal"
              error={Boolean(guest.nameError)}
              helperText={guest.nameError}
              required
            fullWidth
            id={`name-${index}`}
            label="Nome completo"
            name="name"
            value={guest.name}
            onChange={e => handleInputChange(index, 'name', e.target.value)}
            onBlur={e => handleInputChange(index, 'name', e.target.value)}
          />
        </Grid>
        <Grid item>
        <TextField
            margin="normal"
            error={Boolean(guest.rgError)}
            helperText={guest.rgError}
            required
            fullWidth
            name="rg"
            label="RG"
            id={`rg-${index}`}
            value={guest.rg}
            onChange={e => handleInputChange(index, 'rg', e.target.value)}
            onBlur={e => handleInputChange(index, 'rg', e.target.value)}
          />
        </Grid>
        <Grid item>
        <TextField
            margin="normal"
            error={Boolean(guest.cpfError)}
            helperText={guest.cpfError}
            required
            fullWidth
            name="cpf"
            label="CPF"
           id={`cpf-${index}`}
            value={guest.cpf}
            onChange={e => handleInputChange(index, 'cpf', e.target.value)}
            onBlur={e => handleInputChange(index, 'cpf', e.target.value)}
          />
        </Grid>
      </Grid>
      </>
    ));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${Image})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <BookOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Check-in
            </Typography>
            <Typography variant="h5" fontWeight={"bold"} marginTop={2}>
              Cantinho do Mar
            </Typography>


            {loadingReservation ?
              // CARREGANDO RESERVA
              <Typography variant="p" marginTop={2}>
                Carregando sua reserva...
              </Typography>
              : reservation == null ?
              // RESERVA NAO EXISTENTE
                <Typography variant="p" marginTop={2}>
                  Código de reserva não localizado ou reserva concluida!
                </Typography>
              : reservation != null && reservation.guests?.length > 0 ?
                // CHECK REALIZADO
                <>
                <Typography variant="h5" marginTop={2}>
                  Check-in realizado com sucesso!
                </Typography>
                <LoadingButton
                          loading={loading}
                          variant="contained"
                          fullWidth
                          sx={{ mt: 3, mb: 2 }}
                          onClick={() => {setOpenDialog(true)}}
                          disabled={reservation.unlocked >= maxOpenGate}
                          endIcon={<LockIcon />}
                        >
                          Abrir portão
                        </LoadingButton>
                  <Dialog
                    open={openDialog}
                    onClose={()=>{setOpenDialog(false)}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Tem certeza que deseja acionar a abertura do portão?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Utilizado {reservation.unlocked} vezes do total de {maxOpenGate} tentativas.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={()=>{setOpenDialog(false)}}>Cancelar</Button>
                      <Button onClick={() => { handleUnlock(); setOpenDialog(false) }} autoFocus>
                        ABRIR PORTÃO
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Alert severity="warning">
                    Utilize apenas quando for abrir o portão pela primeira vez. <br></br>
                    
                    Utilizado {reservation.unlocked} vezes do total de {maxOpenGate} tentativas.
                      </Alert>
                </>
                :
                // CHECK PENDENTE
                <>
                    <Typography variant="p" marginTop={2}>
                      Procedimento obrigatório de check-in para autorização de entrada no condomínio.
                    </Typography>

                    {generateGuestsForm()}
                    
                    <LoadingButton
                      loading={loading}
                      variant="contained"
                      type="submit"
                      fullWidth
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handleCheckIn}
                    >
                      Check-in
                    </LoadingButton>
                 
                </>
            }
                    <Copyright sx={{ mt: 5 }} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Reservation;
